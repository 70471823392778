<template>
  <div>
    <div v-if="templateID == 'SB_CZTDSYS'">
      <el-table :data="eleTable" style="width: 100%">
        <el-table-column prop="xh" label="序号"> </el-table-column>
        <el-table-column prop="tdbh" label="土地编号" width="190"> </el-table-column>
        <el-table-column prop="zdh" label="宗地号" width="190"> </el-table-column>
        <el-table-column prop="tdzmj" label="土地总面积" width="95"> </el-table-column>
        <el-table-column prop="tddj" label="土地等级" width="80"> </el-table-column>
        <el-table-column prop="jsyj" label="计税依据" width="80"> </el-table-column>
        <el-table-column prop="sebz" label="税额标准(年)" width="110"> </el-table-column>
        <el-table-column prop="ssqq" label="所属期起" width="105"> </el-table-column>
        <el-table-column prop="ssqz" label="所属期至" width="105"> </el-table-column>
        <el-table-column prop="bqynse" label="本期应纳税额" width="105">
        </el-table-column>
        <el-table-column prop="bqjmse" label="本期减免税额" width="105">
        </el-table-column>
        <el-table-column
          prop="bqzzsxgmnsrjze"
          label="本期增值税小规模纳税人减征额"
          width="105"
        >
        </el-table-column>
        <el-table-column prop="bqyjse" label="本期已缴税额" width="105">
        </el-table-column>
        <el-table-column prop="bqybtse" label="期应补(退)税额" width="115">
        </el-table-column>
      </el-table>
    </div>
    <div v-if="templateID == 'SB_FCS'">
      <el-table :data="eleTable" style="width: 100%">
        <el-table-column prop="xh" label="序号"> </el-table-column>
        <el-table-column prop="fcbh" label="房产编号" width="180"> </el-table-column>
        <el-table-column prop="fcyz" label="房产原值" width="130"> </el-table-column>
        <el-table-column prop="qzczfcyz" label="其中：出租房产原值" width="110">
        </el-table-column>
        <el-table-column prop="bqsbzjsr" label="本期申报租金收入" width="80">
        </el-table-column>
        <el-table-column prop="jsbl" label="计税比例" width="80"> </el-table-column>
        <el-table-column prop="sm" label="税目" width="110"> </el-table-column>
        <el-table-column prop="sl" label="税率" width="105"> </el-table-column>
        <el-table-column prop="ssqq" label="所属期起" width="105"> </el-table-column>
        <el-table-column prop="ssqz" label="所属期至" width="105"> </el-table-column>
        <el-table-column prop="bqynse" label="本期应纳税额" width="105">
        </el-table-column>
        <el-table-column prop="bqjmse" label="本期减免税额" width="105">
        </el-table-column>
        <el-table-column
          prop="bqzzsxgmnsrjze"
          label="本期增值税小规模纳税人减征额"
          width="105"
        >
        </el-table-column>
        <el-table-column prop="bqyjse" label="本期已缴税额" width="115">
        </el-table-column>
        <el-table-column prop="bqybtse" label="期应补(退)税额" width="115">
        </el-table-column>
      </el-table>
    </div>
    <div v-if="templateID == 'SB_YHSSYMX'">
      <el-table :data="eleTable" style="width: 100%">
        <el-table-column prop="xh" label="序号"> </el-table-column>
        <el-table-column prop="yspzswbh" label="应税凭证税务编号" width="190">
        </el-table-column>
        <el-table-column prop="yspzbh" label="应税凭证编号" width="190">
        </el-table-column>
        <el-table-column prop="yspzmc" label="应税凭证名称" width="105"> </el-table-column>
        <el-table-column prop="sbqxlx" label="申报期限类型" width="110"> </el-table-column>
        <el-table-column prop="yspzsl" label="应税凭证数量" width="110"> </el-table-column>
        <el-table-column prop="sm" label="税目" width="110"> </el-table-column>
        <el-table-column prop="zm" label="子目" width="105"> </el-table-column>
        <el-table-column prop="skssqq" label="税款所属期起" width="105">
        </el-table-column>
        <el-table-column prop="skssqz" label="税款所属期止" width="105">
        </el-table-column>
        <el-table-column prop="yspzslrq" label="应税凭证书立日期" width="115">
        </el-table-column>
        <el-table-column prop="jsje" label="计税金额" width="120"> </el-table-column>
        <el-table-column prop="sjjsrq" label="实际结算日期" width="105">
        </el-table-column>
        <el-table-column prop="sjjsje" label="实际结算金额" width="115">
        </el-table-column>
        <el-table-column prop="sl" label="税率" width="100"> </el-table-column>
        <el-table-column prop="ynse" label="应纳税额" width="100"> </el-table-column>
        <el-table-column prop="jmxzdmhxmmc" label="减免性质代码和项目名称" width="115">
        </el-table-column>
        <el-table-column prop="jmse" label="减免税额" width="115"> </el-table-column>
        <el-table-column prop="dfslmc" label="对方书立人信息-对方书立人名称" width="115">
        </el-table-column>
        <el-table-column
          prop="dfslnsrsbh"
          label="对方书立人信息-对方书立人纳税人识别号（统一社会信用代码）"
          width="115"
        >
        </el-table-column>
        <el-table-column
          prop="dfslrsjje"
          label="对方书立人信息-对方书立人涉及金额"
          width="115"
        >
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    eleTable: {
      type: Array,
      default: () => null,
    },
    templateID: [String],
  },
  data() {
    return {};
  },
};
</script>
