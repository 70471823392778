<template>
  <el-dialog
    :visible.sync="show"
    width="600px"
    :show-close="true"
    class="correctDialog"
  >
    <div>
      <el-carousel indicator-position="outside" height="350px" :interval="5000">
        <el-carousel-item>
          <div>
            <img src="@/assets/test/instruction0.png" alt class="pic" />
            <div class="title">表头信息</div>
            <div class="desc">确保数据填写准确，请仔细核对本年累计金额与上年金额，避免数据填写错误</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img src="@/assets/test/instruction1.gif" alt class="pic" />
            <div class="title">Excel表格数据复制</div>
            <div class="desc">用户可将Excel表格内数据批量复制粘贴到手工填报页面内</div>
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <div>
            <img src="@/assets/test/instruction2.png" alt class="pic" />
            <div class="title">科目自动核算</div>
            <div class="desc">手工填报根据国家标准表格制定，部分科目可自动进行核算</div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <button class="btn btn-primary btn-finish" @click="handleInstructionDialogClose">完成</button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  data: function() {
    return {
      show: false
    };
  },
  mounted() {
      let ManualFillInstructionDisplayed = localStorage.getItem("ManualFillInstructionDisplayed");
    
      if(ManualFillInstructionDisplayed!='1'){
          this.show = true;
          
      }
      
  },
  methods: {
    handleInstructionDialogClose() {
        localStorage.setItem("ManualFillInstructionDisplayed",1);
        this.show = false;
    }
  }
};
</script>

<style lang="stylus" scoped>
.pic {
  width: 100%;
}

.title {
  text-align: center;
  font-size: 18px;
  margin-top: 32px;
  color: #333;
  line-height: 1.5;
}

.desc {
  text-align: center;
  color: #666;
  font-size: 14px;
  line-height: 1.5;
}

.btn-finish {
  width: 400px;
  display: block;
  margin: 0 auto 10px;
}
</style>